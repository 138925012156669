<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-bonuspoints" /> 余额：{{
                  price
                }}
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div>
              <div class="listItem">
                <div :class="[selectindex == item.id ? 'item select' : 'item']" v-for="(item, index) in list" :key="index"
                  @click="selectItem(item.id)">
                  <div class="title">{{ item.money }}元</div>
                  <div class="info">赠送{{ item.money }}元</div>
                </div>
                <div class="item" @click="selectItem(0)">
                  <div class="title">其他金额</div>
                  <div class="info"><a-input prefix="￥" suffix="RMB" v-model="other" /></div>
                </div>
              </div>
              <div class="tips">
                <div class="title">
                  充值说明
                </div>
                <div class="txt">
                  充值后帐户的金额不能提现，可用于商城消费使用
                </div>
                <div class="btnarea">
                  <div class="btn">
                    <a-button type="primary" size="large" @click="onSubmit" block>充值</a-button>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>


import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import {
  Icon
} from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    return {
      user: {},
      price: 0,
      selectindex: -1,
      list: [],
      other: 0,
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
    this.api.rechargeItems({}).then(res => {
      this.list = res;
    }).catch(err => {
      console.log(err);
    });
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
    this.api
      .getuserWalletDetail({})
      .then((res) => {
        this.price = res.balance + res.balanceB;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    go(e) {
      console.log(e);
      this.$router.push(e);
    },
    selectItem(index) {
      this.selectindex = index;
    },
    onSubmit() {
      let that = this;
      let topprice = 0;
      if (this.other && this.other > 0) {
        this.selectindex = 0;
        topprice = this.other;
      } else {
        if (this.selectindex < 0) {
          this.$message.error("请选择充值金额", 3);
          return;
        }
      }
      this.api.createRecharge({
        id: that.selectindex,
        price: topprice,
      }).then(res => {
        let orderNo = res.orderNo;
        that.$router.push('/user/TopUpPaymethod?orderNo=' + orderNo);
        //let payParam = res.payParam;
        //支付测试
        // that.api.payRecharge({
        //   orderNo: orderNo,
        // }).then(res2 => {
        //   console.log(res2);
        //   that.$message.success("充值成功", 3);
        //   that.$router.push('/user/depositresult?orderNo='+orderNo);
        // }).catch(err2 => {
        //   console.log(err2);
        // });
      }).catch(err => {
        console.log(err);
      });
    }
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.listItem {
  width: 100%;
  padding: 10px;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  .item {
    height: 100px;
    background: #f4f4f5;
    border-radius: 8px;
    padding: 11px;
    position: relative;

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }

    .info {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
  }

  .select {
    color: #ff5717;
    border: 0.5px solid #ff5717;
  }
}

.tips {
  padding-left: 20px;
  margin-top: 20px;
  color: #000;

  .title {
    margin: 10px 0;
    font-size: 20px;
  }

  .btnarea {
    position: relative;
    width: 100%;
    padding-top: 30px;
    display: flex;
    text-align: center;
    justify-content: center;

    .btn {
      position: relative;
      width: 40%;
      text-align: center;
    }
  }
}
</style>