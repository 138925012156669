<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-bonuspoints" /> 余额：{{
                  price
                }}
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div>
              <div class="bla-border">
                <div class="bla-head">
                  <div class="bla-money">
                    总资产(元)
                    <div class="bla-txt">
                      {{ price }}
                    </div>
                  </div>
                  <div class="bla-btn">
                    <a-button type="danger" @click="go('/user/TopUp')"> 充值 </a-button>
                  </div>
                </div>
                <div class="bla-foot">
                  <div class="bla-invest-total">
                    账户A(元)
                    <div class="bla-txt">{{ balance }}</div>
                  </div>
                  <div class="bla-consume-total">
                    账户B(元)
                    <div class="bla-txt">{{ balanceB }}</div>
                  </div>
                </div>
                <div class="bla-foot">
                  <div class="bla-invest-total">
                    累计充值
                    <div class="bla-txt">{{ totalRecharge }}</div>
                  </div>
                  <div class="bla-consume-total">
                    累计消费
                    <div class="bla-txt">{{ totalPay }}</div>
                  </div>
                </div>
              </div>
              <a-layout-header>
                <a-row type="flex" justify="center" align="middle" class="top">
                  <a-col :span="8" @click="go('/user/BlancesLog')">
                    <a-icon type="account-book" /> 账单记录
                  </a-col>
                  <a-col :span="8" @click="go('/user/BlancesLog')">
                    <icon-font type="icon-bonuspoints" /> 消费记录
                  </a-col>
                  <a-col :span="8" @click="go('/user/Bonuspoints')">
                    <icon-font type="icon-coupons" /> 积分中心
                  </a-col>
                </a-row>
              </a-layout-header>
              <div class="sign-coupon">
                <div class="item sign" @click="go('/user/Sign')">
                  <div class="txt">
                    签到领积分
                    <div class="info">赚积分抵现金</div>
                  </div>
                  <a-icon type="star" class="u-icon--right" />
                </div>
                <div class="item coupon" @click="go('/user/Getcoupons')">
                  <div class="txt">
                    领取优惠券
                    <div class="info">领取优惠券</div>
                  </div>
                  <a-icon type="star" class="u-icon--right" />
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
  import Foot from "@/components/foot";
  import Nav from "@/components/Nav.vue";
  import Menu from "../user/components/menu";
  import {
    Icon
  } from "ant-design-vue";
  const IconFont = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
  });
  export default {
    components: {
      Nav,
      Foot,
      Menu,
      IconFont,
    },
    data() {
      return {
        user: {},
        price: 0.0,
        balance: 0.0,
        balanceB: 0.0,
        totalRecharge: 0.0,
        totalPay: 0.0,
      };
    },
    computed: {},
    created() {
      if (this.$route.name === "Home") this.main_swiper = true;
    },
    mounted() {
      this.user = this.storage.getItem("user");
      if (!this.user) {
        this.getUserInfo();
      }
      this.api
        .getuserWalletDetail({})
        .then((res) => {
          this.price = res.balance + res.balanceB;
          this.totalRecharge = res.totalRecharge;
          this.totalPay = res.totalPay;
          this.balance = res.balance;
          this.balanceB = res.balanceB;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    watch: {
      // 如果路由有变化，会再次执行该方法
      $route: "fetchDate",
    },
    methods: {
      fetchDate() {
        if (this.$route.name !== "Home") {
          this.main_swiper = false;
        } else {
          this.main_swiper = true;
        }
      },
      getUserInfo() {
        this.api
          .getuserDetail({})
          .then((res) => {
            this.storage.setItem("user", res);
            this.user =  res;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      go(e) {
        this.$router.push(e);
      },
    },
  };
</script>


<style lang="less">
  @import "~ant-design-vue/dist/antd.less";

  .page {
    background: #f5f5f5;
  }

  .wrap {
    height: 100%;

    // padding-bottom: 20px;
  }

  .ant-layout {
    height: 100%;
  }

  .ant-layout-sider,
  .ant-layout-content,
  .ant-layout-header,
  .ant-layout-footer {
    background: #ffffff !important;
    padding: 10px;
  }

  .ant-layout-sider {
    margin-right: 10px;
    text-align: center;
  }

  .ant-layout-content {
    margin-top: 10px;
  }

  .ant-layout-header {
    height: auto;

    .top {
      .ant-col {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  .user {
    .user-name {
      font-size: 14px;
      margin-top: 10px;
    }

    .other-info {
      text-align: left;
      margin-left: 30px;

      span {
        color: @text-color-secondary;
      }
    }
  }

  .menu {
    text-align: left;
    padding-left: 30px;
    line-height: 28px;
    margin-top: 30px;
    border-top: 1px solid #e2e2e2;

    dl {
      padding-top: 10px;

      dt {
        font-size: 16px;
        line-height: 28px;
      }

      dd {
        color: @text-color-secondary;
      }
    }
  }

  div.content-wrap {
    padding: 15px;
  }

  .num {
    color: red;
    padding-right: 10px;
  }

  .bla-border {
    border: 0 solid #fff;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    background: #fff;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .bla-top {
    background-size: cover;
    padding: 15px;
  }

  .bla-head {
    display: flex;
    justify-content: space-between;

    .bla-txt {
      font-size: 36px;
      color: #CF0022;
      margin-top: 5px;
    }
  }

  .bla-foot {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .bla-invest-total {
      .bla-txt {
        font-size: 36px;
        color: #CF0022;
        margin-top: 5px;
      }
    }

    .bla-consume-total {
      .bla-txt {
        font-size: 36px;
        color: #CF0022;
        margin-top: 5px;
      }
    }
  }

  .sign-coupon {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .sign {
      background: #fff6d1;
    }

    .coupon {
      background: #fff3f3;
    }

    .item {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      width: 48%;
      padding: 9px;
      color: #CF0022;

      .u-icon--right {
        flex-direction: row;
        align-items: center;
        font-size: 30px;
      }

      .info {
        font-weight: 400;
        font-size: 11px;
        margin-top: 5px;
      }

      .txt {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
</style>